import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import './AdminDashboard.css';

const AdminDashboard = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [companies, setCompanies] = useState([]);
    const [editingCompany, setEditingCompany] = useState(null);
    const [editingQuestion, setEditingQuestion] = useState(null);

    const [newCompany, setNewCompany] = useState({
        name: '',
        date: '',
        solutions_available: false
    });

    const [newQuestion, setNewQuestion] = useState({
        company_id: '',
        title: '',
        google_doc_link: '',
        problem_statement: '',
        solution_code: ''
    });

    const [modalType, setModalType] = useState('');

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('/company_oa.php');
            if (response.data.status === 'success') {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
            setModalMessage('Error fetching companies. Please try again.');
            setShowModal(true);
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    const handleAddCompany = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/company_oa.php', {
                action: 'add_company',
                ...newCompany
            });
            
            if (response.data.status === 'success') {
                setModalMessage('Company added successfully!');
                setShowModal(true);
                setNewCompany({
                    name: '',
                    date: '',
                    solutions_available: false
                });
                fetchCompanies();
            }
        } catch (error) {
            console.error('Error adding company:', error);
            setModalMessage('Error adding company. Please try again.');
            setShowModal(true);
        }
    };

    const handleAddQuestion = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/company_oa.php', {
                action: 'add_question',
                ...newQuestion
            });

            if (response.data.status === 'success') {
                setModalMessage('Question added successfully!');
                setShowModal(true);
                setNewQuestion({
                    company_id: '',
                    title: '',
                    google_doc_link: '',
                    problem_statement: '',
                    solution_code: ''
                });
                fetchCompanies();
            }
        } catch (error) {
            console.error('Error adding question:', error);
            setModalMessage('Error adding question. Please try again.');
            setShowModal(true);
        }
    };

    const handleEditCompany = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                action: 'edit_company',
                id: editingCompany.id,
                name: editingCompany.name,
                date: editingCompany.date,
                solutions_available: editingCompany.solutions_available
            };

            console.log('Sending edit company request:', formData);

            const response = await axios.post(
                '/company_oa.php',
                formData
            );

            console.log('Server response:', response.data);

            if (response.data.status === 'success') {
                setModalType('success');
                setModalMessage('Company updated successfully!');
                setShowModal(true);
                setEditingCompany(null);
                await fetchCompanies(); // Refresh the list
            } else {
                throw new Error(response.data.message || 'Failed to update company');
            }
        } catch (error) {
            console.error('Error updating company:', error);
            setModalType('error');
            setModalMessage(error.message || 'Error updating company');
            setShowModal(true);
        }
    };

    const handleEditQuestion = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                '/company_oa.php',
                {
                    action: 'edit_question',
                    ...editingQuestion
                }
            );

            if (response.data.status === 'success') {
                setModalMessage('Question updated successfully!');
                setModalType('success');
                setShowModal(true);
                setEditingQuestion(null);
                fetchCompanies();
            } else {
                throw new Error(response.data.message || 'Failed to update question');
            }
        } catch (error) {
            console.error('Error updating question:', error);
            setModalMessage('Error updating question: ' + error.message);
            setModalType('error');
            setShowModal(true);
        }
    };

    return (
        <div className="admin-dashboard">
            <h1>Admin Dashboard</h1>

            <section className="add-company">
                <h2>Add New Company</h2>
                <form onSubmit={handleAddCompany}>
                    <input
                        type="text"
                        placeholder="Company Name"
                        value={newCompany.name}
                        onChange={(e) => setNewCompany({ ...newCompany, name: e.target.value })}
                        required
                    />
                    <input
                        type="date"
                        value={newCompany.date}
                        onChange={(e) => setNewCompany({ ...newCompany, date: e.target.value })}
                        required
                    />
                    <div className="checkbox-wrapper">
                        <label>
                            <input
                                type="checkbox"
                                checked={newCompany.solutions_available}
                                onChange={(e) => setNewCompany({ ...newCompany, solutions_available: e.target.checked })}
                            />
                            Solutions Available
                        </label>
                    </div>
                    <button type="submit">Add Company</button>
                </form>
            </section>

            <section className="add-question">
                <h2>Add New Question</h2>
                <form onSubmit={handleAddQuestion}>
                    <select
                        value={newQuestion.company_id}
                        onChange={(e) => setNewQuestion({ ...newQuestion, company_id: e.target.value })}
                        required
                    >
                        <option value="">Select Company</option>
                        {companies.map((company) => (
                            <option key={company.id} value={company.id}>{company.name}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        placeholder="Question Title"
                        value={newQuestion.title}
                        onChange={(e) => setNewQuestion({ ...newQuestion, title: e.target.value })}
                        required
                    />
                    <input
                        type="url"
                        placeholder="GitHub Images Folder or Google Doc Link"
                        value={newQuestion.google_doc_link}
                        onChange={(e) => setNewQuestion({ ...newQuestion, google_doc_link: e.target.value })}
                        required
                    />
                    <textarea
                        className="problem-statement-input"
                        placeholder="Problem Statement HTML"
                        value={newQuestion.problem_statement}
                        onChange={(e) => setNewQuestion({ ...newQuestion, problem_statement: e.target.value })}
                        rows={10}
                    ></textarea>
                    <div className="form-group">
                        <label>Solution Code (Optional):</label>
                        <textarea
                            className="code-editor"
                            placeholder="Enter solution code here (C++, Java, Python, etc.)"
                            value={newQuestion.solution_code}
                            onChange={(e) => setNewQuestion({ ...newQuestion, solution_code: e.target.value })}
                            rows={15}
                        />
                    </div>
                    <button type="submit">Add Question</button>
                </form>
            </section>

            <section className="companies-list">
                <h2>Manage Companies and Questions</h2>
                {companies.map((company) => (
                    <div key={company.id} className="company-item">
                        {editingCompany && editingCompany.id === company.id ? (
                            <form onSubmit={handleEditCompany}>
                                <input
                                    type="text"
                                    value={editingCompany.name}
                                    onChange={(e) => setEditingCompany({
                                        ...editingCompany,
                                        name: e.target.value
                                    })}
                                    required
                                />
                                <input
                                    type="date"
                                    value={editingCompany.date}
                                    onChange={(e) => setEditingCompany({
                                        ...editingCompany,
                                        date: e.target.value
                                    })}
                                    required
                                />
                                <div className="checkbox-wrapper">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={editingCompany.solutions_available}
                                            onChange={(e) => setEditingCompany({
                                                ...editingCompany,
                                                solutions_available: e.target.checked
                                            })}
                                        />
                                        Solutions Available
                                    </label>
                                </div>
                                <div className="edit-actions">
                                    <button type="submit">Save</button>
                                    <button type="button" onClick={() => setEditingCompany(null)}>Cancel</button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <h3>{company.name}</h3>
                                <p>Date: {company.date}</p>
                                <p>Solutions Available: {company.solutions_available ? 'Yes' : 'No'}</p>
                                <div className="company-actions">
                                    <button onClick={() => {
                                        const companyToEdit = {
                                            id: company.id,
                                            name: company.name,
                                            date: company.date,
                                            solutions_available: Boolean(company.solutions_available)
                                        };
                                        console.log('Setting up company edit:', companyToEdit);
                                        setEditingCompany(companyToEdit);
                                    }}>
                                        Edit
                                    </button>
                                </div>
                            </>
                        )}

                        <div className="questions-list">
                            <h4>Questions:</h4>
                            {company.questions && company.questions.map((question) => (
                                <div key={question.id} className="question-item">
                                    {editingQuestion && editingQuestion.id === question.id ? (
                                        <form onSubmit={(e) => handleEditQuestion(e)} className="edit-question-form">
                                            <div className="form-group">
                                                <label>Title:</label>
                                                <input
                                                    type="text"
                                                    value={editingQuestion.title}
                                                    onChange={(e) => setEditingQuestion({
                                                        ...editingQuestion,
                                                        title: e.target.value
                                                    })}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Problem Link:</label>
                                                <input
                                                    type="url"
                                                    value={editingQuestion.google_doc_link}
                                                    onChange={(e) => setEditingQuestion({
                                                        ...editingQuestion,
                                                        google_doc_link: e.target.value
                                                    })}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Problem Statement:</label>
                                                <textarea
                                                    value={editingQuestion.problem_statement || ''}
                                                    onChange={(e) => setEditingQuestion({
                                                        ...editingQuestion,
                                                        problem_statement: e.target.value
                                                    })}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Solution Code (Optional):</label>
                                                <textarea
                                                    className="code-editor"
                                                    value={editingQuestion.solution_code || ''}
                                                    onChange={(e) => setEditingQuestion({
                                                        ...editingQuestion,
                                                        solution_code: e.target.value
                                                    })}
                                                    placeholder="Enter solution code here"
                                                    rows={15}
                                                />
                                            </div>
                                            <div className="edit-actions">
                                                <button type="submit" className="save-btn">Save Changes</button>
                                                <button 
                                                    type="button" 
                                                    className="cancel-btn"
                                                    onClick={() => setEditingQuestion(null)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        <>
                                            <h4>{question.title}</h4>
                                            <div className="question-details">
                                                <p>Problem Link: <a href={question.google_doc_link} target="_blank" rel="noopener noreferrer">View Problem</a></p>
                                                {question.solution_code && (
                                                    <p>Solution Code: <pre>{question.solution_code}</pre></p>
                                                )}
                                            </div>
                                            <div className="question-actions">
                                                <button onClick={() => {
                                                    const questionToEdit = {
                                                        id: question.id,
                                                        company_id: company.id,
                                                        title: question.title,
                                                        google_doc_link: question.google_doc_link,
                                                        problem_statement: question.problem_statement || '',
                                                        solution_code: question.solution_code || ''
                                                    };
                                                    console.log('Setting up question edit:', questionToEdit);
                                                    setEditingQuestion(questionToEdit);
                                                }}>
                                                    Edit
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </section>

            {showModal && (
                <div className="modal-overlay" onClick={() => setShowModal(false)}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className={`modal-message ${modalType === 'success' ? 'modal-success' : 'modal-error'}`}>
                            {modalMessage}
                        </div>
                        <button 
                            className="modal-close"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboard;
