import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaLock, FaTimes, FaArrowLeft, FaCode, FaExternalLinkAlt } from 'react-icons/fa';
import axios from 'axios';
import './ProblemViewer.css';

const ProblemViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [email, setEmail] = useState('');
  const [problemData, setProblemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [testCases] = useState([
    { id: 0, passed: true, hidden: false },
    { id: 1, passed: true, hidden: false },
    { id: 2, passed: true, hidden: true },
    { id: 3, passed: true, hidden: true },
    { id: 4, passed: true, hidden: true },
    { id: 5, passed: true, hidden: true }
  ]);

  useEffect(() => {
    const fetchProblemData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (location.state?.problem) {
          setProblemData({
            title: location.state.problem.title,
            company: location.state.problem.company_name,
            difficulty: location.state.problem.difficulty || 'Medium',
            description: location.state.problem.problem_statement,
            google_doc_link: location.state.problem.google_doc_link
          });
        } 
        else if (id) {
          const response = await axios.get(`/company_oa.php?action=get_problem&id=${id}`);
          
          if (response.data.status === 'success') {
            setProblemData({
              title: response.data.data.title,
              company: response.data.data.company_name,
              difficulty: response.data.data.difficulty || 'Medium',
              description: response.data.data.problem_statement,
              google_doc_link: response.data.data.google_doc_link
            });
          } else {
            throw new Error(response.data.message || 'Failed to fetch problem data');
          }
        }
      } catch (error) {
        console.error('Error fetching problem:', error);
        setError('Failed to load problem data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchProblemData();
  }, [id, location.state]);

  const handleSolutionSubmit = async (email, problemId, companyName, problemTitle, e) => {
    e.preventDefault();
    
    if (!email) {
      alert('Please enter your email address');
      return;
    }

    try {
      const response = await axios.post('/company_oa.php', {
        action: 'request_solution',
        email: email,
        problem_id: problemId,
        company_name: companyName,
        problem_title: problemTitle
      });

      if (response.data.status === 'success') {
        alert('Solution request sent successfully! Check your email.');
        setShowPaymentModal(false);
        setEmail('');
      } else {
        alert(response.data.message || 'Failed to send solution request');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send solution request. Please try again later.');
    }
  };

  const handleSolutionClick = () => {
    setShowPaymentModal(true);
  };

  const handlePaymentRedirect = (problemName) => {
    const baseUrl = 'https://cphelper.online/#/problem-solutions/';
    const formattedProblemName = encodeURIComponent(problemName);
    window.location.href = `${baseUrl}${formattedProblemName}/9`;
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <div className="loading-text">Loading Problem...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <div className="error-message">{error}</div>
        <button className="back-button" onClick={() => navigate('/')}>
          <FaArrowLeft /> Back to Problems
        </button>
      </div>
    );
  }

  return (
    <div className="problem-viewer">
      <div className="problem-content">
        <div className="navigation-header">
          <button className="back-button" onClick={() => navigate('/')}>
            <FaArrowLeft /> Back to Problems
          </button>
        </div>

        <div className="problem-header">
          <h1>{problemData?.title}</h1>
          <div className="problem-meta">
            <span className="company-tag">
              {problemData?.company}
            </span>
            <span className={`difficulty ${problemData?.difficulty?.toLowerCase()}`}>
              {problemData?.difficulty}
            </span>
            {problemData?.google_doc_link && (
              <a 
                href={problemData.google_doc_link}
                target="_blank"
                rel="noopener noreferrer"
                className="view-problem-btn"
              >
                <FaExternalLinkAlt /> Original Problem
              </a>
            )}
          </div>
        </div>

        <div className="problem-statement">
          <div 
            className="problem-content"
            dangerouslySetInnerHTML={{ __html: problemData?.description || 'Problem description not available.' }}
          />
        </div>
      </div>

      <div className="solution-section">
        <div className="solution-header">
          <h2>Complete Solution</h2>
          <div className="premium-tag">100%</div>
        </div>
        
        <div className="test-cases-section">
          <div className="test-status">
            <div className="status-header">
              <h3>Test Results</h3>
              <div className="compilation-status">
                Compiled successfully. All available test cases passed!
              </div>
            </div>
            <div className="test-cases-grid">
              {testCases.map(test => (
                <div key={test.id} className={`test-case ${test.hidden ? 'hidden' : ''}`}>
                  <div className="test-case-icon">✓</div>
                  <div className="test-case-text">
                    Test case {test.id} {test.hidden && '(Hidden)'}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="solution-preview">
          <div className="blurred-solution">
            <pre>
              <code>{/* Blurred solution code here */}</code>
            </pre>
          </div>
          <div className="premium-overlay">
            <div className="premium-content">
              <FaLock className="lock-icon" />
              <h2>Premium Solution</h2>
              <p>Get access to detailed solutions with step-by-step explanations!</p>
              <button 
                className="unlock-button"
                onClick={handleSolutionClick}
              >
                Unlock Solution
              </button>
            </div>
          </div>
        </div>
      </div>

      {showPaymentModal && (
        <div className="payment-modal-overlay" onClick={() => setShowPaymentModal(false)}>
          <div className="payment-modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setShowPaymentModal(false)}>
              <FaTimes />
            </button>
            
            <h2>Get Complete Solution</h2>
            
            <div className="payment-price">
              <div className="price-amount">₹9</div>
              <div className="price-text">One-time payment</div>
            </div>

            <div className="modal-description">
              We take this small amount so that we can keep uploading new questions for you and keep working to help you.
            </div>

            <button 
              className="payment-button"
              onClick={() => handlePaymentRedirect(problemData?.title)}
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProblemViewer;