import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { 
  FaChevronDown, 
  FaChevronUp, 
  FaBuilding, 
  FaSearch, 
  FaTimes, 
  FaLock, 
  FaEnvelope, 
  FaExternalLinkAlt,
  FaAmazon,
  FaGoogle,
  FaMicrosoft,
  FaApple,
  FaFacebook,
  FaLinkedin,
  FaUber,
  FaTwitter,
  FaStripe,
  FaSnapchat,
  FaHeadset,
  FaTelegram,
  FaGithub,
  FaAngleDown
} from 'react-icons/fa';
import { SiNetflix, SiTesla, SiOracle, SiAdobe } from 'react-icons/si';
import { GiBatMask } from 'react-icons/gi'; // for Batman logo
import './CompanyOA.css';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import './SearchMinimal.css';

const CompanyOA = () => {
  const [companies, setCompanies] = useState([]);
  const [expandedCompany, setExpandedCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedSolutionButton, setExpandedSolutionButton] = useState(null);
  const [solutionEmail, setSolutionEmail] = useState('');
  const [expandedProblem, setExpandedProblem] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isSearching, setIsSearching] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState(null);
  const searchDebounceRef = useRef(null);

  const fetchCompanies = useCallback(async () => {
    try {
      setError(null);
      
      const response = await axios.get(`/company_oa.php`, {
        params: {
          page: currentPage,
          per_page: itemsPerPage
        },
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: false,
        timeout: 10000
      });

      if (response.data && response.data.status === 'success') {
        setCompanies(response.data.data);
        setTotalPages(Math.ceil(response.data.total / itemsPerPage));
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (err) {
      console.error('Error fetching companies:', err);
      setError(err.message || 'Failed to load companies. Please try again later.');
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const toggleCompany = (companyId) => {
    setExpandedCompany(expandedCompany === companyId ? null : companyId);
  };

  const filterCompanies = useCallback(() => {
    if (!searchTerm.trim()) {
      return companies;
    }

    const searchLower = searchTerm.toLowerCase();
    
    return companies.filter(company => {
      // Check if company name matches
      const companyMatches = company.name.toLowerCase().includes(searchLower);
      
      // Check if any questions match
      const questionsMatch = company.questions?.some(question =>
        question.title.toLowerCase().includes(searchLower)
      );

      return companyMatches || questionsMatch;
    }).map(company => {
      // If searching for a question, only show matching questions
      if (!company.name.toLowerCase().includes(searchLower)) {
        return {
          ...company,
          questions: company.questions.filter(question =>
            question.title.toLowerCase().includes(searchLower)
          )
        };
      }
      return company;
    });
  }, [companies, searchTerm]);

  const handleSolutionClick = (questionId, e) => {
    e.stopPropagation();
    setExpandedSolutionButton(expandedSolutionButton === questionId ? null : questionId);
  };

  const handleSolutionSubmit = async (questionId, companyName, questionTitle, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);
    
    try {
        const response = await axios.post('/send_solution_mail.php', {
            email: solutionEmail,
            problemName: questionTitle,
            companyName: companyName
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        if (response.data.status === 'success') {
            alert('Payment instructions have been sent to your email!');
        } else {
            throw new Error(response.data.message || 'Failed to send email');
        }
    } catch (error) {
        console.error('Error:', error);
        alert('Failed to send email. Please try again later.');
    } finally {
        setIsSubmitting(false);
        setExpandedSolutionButton(null);
        setSolutionEmail('');
    }
  };

  const getCompanyIcon = (companyName) => {
    const name = companyName.toLowerCase();
    
    const iconMap = {
      'amazon': FaAmazon,
      'google': FaGoogle,
      'microsoft': FaMicrosoft,
      'apple': FaApple,
      'meta': FaFacebook,
      'facebook': FaFacebook,
      'linkedin': FaLinkedin,
      'uber': FaUber,
      'twitter': FaTwitter,
      'netflix': SiNetflix,
      'tesla': SiTesla,
      'oracle': SiOracle,
      'adobe': SiAdobe,
      'stripe': FaStripe,
      'snapchat': FaSnapchat
    };

    for (const [keyword, Icon] of Object.entries(iconMap)) {
      if (name.includes(keyword)) {
        return Icon;
      }
    }

    return FaBuilding;
  };

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 1) return '1 day ago';
    if (diffDays < 30) return `${diffDays} days ago`;
    if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      return `${months} ${months === 1 ? 'month' : 'months'} ago`;
    }
    const years = Math.floor(diffDays / 365);
    return `${years} ${years === 1 ? 'year' : 'years'} ago`;
  };

  const handleProblemClick = (problem) => {
    navigate(`/problem/${problem.id}`, { 
      state: { 
        problem: {
          ...problem,
          company: problem.company_name || 'Unknown Company',
          difficulty: problem.difficulty || 'Medium',
          description: problem.description || problem.content || 'Description not available.',
          examples: problem.examples || []
        } 
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const handleEditQuestion = async (questionId, newData) => {
    try {
        // Add your edit question logic here
        // await axios.put(`/api/questions/${questionId}`, newData);
        // fetchCompanies(); // Refresh the data
    } catch (error) {
        console.error('Error editing question:', error);
    }
  };

  // Add this helper function to format the problem statement
  const formatProblemStatement = (rawText) => {
    if (!rawText) return '';

    // First, convert the raw text to proper HTML structure
    const processedText = rawText
      .replace(/\\n\\n/g, '</div><div class="section">') // Double newlines create new sections
      .replace(/\\n/g, '<br>') // Single newlines become line breaks
      .replace(/`([^`]+)`/g, '<code>$1</code>'); // Convert backticks to code tags

    // Create sections for different parts of the problem
    let html = `
      <div class="problem-container">
        <div class="problem-description">
          ${processedText}
        </div>
      </div>
    `;

    // Process special sections
    html = html
      .replace('Input Format', '<h3 class="section-title">Input Format</h3>')
      .replace('Output Format', '<h3 class="section-title">Output Format</h3>')
      .replace('Constraints', '<h3 class="section-title">Constraints</h3>')
      .replace('Examples', '<h3 class="section-title">Examples</h3>')
      .replace('Note', '<h3 class="section-title">Note</h3>');

    return html;
  };

  const PaginationControls = () => {
    return (
      <div className="pagination">
        <button 
          className="pagination-button"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        
        <button 
          className="pagination-button"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };

  const handleSearch = useCallback(async (searchTerm) => {
    setSearchTerm(searchTerm);
    
    // Clear previous timeout
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }

    if (!searchTerm.trim()) {
      setGlobalSearchResults(null);
      return;
    }

    setIsSearching(true);

    // Debounce the search
    searchDebounceRef.current = setTimeout(async () => {
      try {
        const response = await axios.get(`/company_oa.php`, {
          params: {
            action: 'search',
            term: searchTerm
          }
        });

        if (response.data.status === 'success') {
          setGlobalSearchResults(response.data.data);
        }
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsSearching(false);
      }
    }, 300); // 300ms debounce
  }, []);

  return (
    <div className="company-oa-wrapper">
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : (
        <main>
          <div className="search-minimal-wrapper">
            <div className="cphelper-logo">
              OA<span>Helper</span>
            </div>
            <div className="search-minimal-container">
              <FaSearch className="search-minimal-icon" />
              <input
                type="text"
                placeholder="Search companies or questions..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="search-minimal-input"
              />
              {isSearching ? (
                <div className="search-minimal-loading">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              ) : searchTerm ? (
                <button 
                  className="search-minimal-clear"
                  onClick={() => {
                    setSearchTerm('');
                    setGlobalSearchResults(null);
                  }}
                >
                  <FaTimes />
                </button>
              ) : null}
            </div>
          </div>
          {(globalSearchResults || filterCompanies().length > 0) && (
            <div className="table-scroll-container">
              <div className="table-inner-container">
                <div className="company-table">
                  <div className="company-table-header">
                    <div>Company</div>
                    <div>Date</div>
                    <div className="questions-count">Questions</div>
                    <div>Solutions</div>
                    <div className="desktop-only"></div>
                  </div>
                  <div className="company-table-body">
                    {globalSearchResults !== null ? globalSearchResults.map((company) => (
                      <div key={company.id} className="company-row">
                        <div className="company-data" onClick={() => toggleCompany(company.id)}>
                          <div className="company-name">
                            <div className="company-icon-wrapper">
                              {React.createElement(getCompanyIcon(company.name), {
                                className: "company-icon"
                              })}
                            </div>
                            <span className="company-name-text">{company.name}</span>
                          </div>
                          <div className="date-container">
                            {new Date(company.date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })}
                            <span className="date-ago">
                              {getTimeAgo(new Date(company.date))}
                            </span>
                          </div>
                          <div className="questions-count">
                            {company.questions ? company.questions.length : 0}
                          </div>
                          <div className="company-solutions">
                            <span className={`solution-badge ${company.solutions_available ? 'available' : 'unavailable'}`}>
                              {company.solutions_available ? 'Available' : 'Unavailable'}
                            </span>
                          </div>
                          <div className="toggle-icon desktop-only">
                            {expandedCompany === company.id ? <FaChevronUp /> : <FaChevronDown />}
                          </div>
                        </div>
                        {expandedCompany === company.id && (
                          <div className="company-questions">
                            {company.questions.length > 0 ? (
                              company.questions.map((question, index) => (
                                <div key={question.id} className="question-item">
                                  <div className="question-preview">
                                    <div className="question-info">
                                      <span className="question-number">{index + 1}.</span>
                                      <h4 
                                        className="question-title"
                                        onClick={(e) => handleProblemClick(question)}
                                      >
                                        {question.title}
                                        {!expandedProblem && 
                                            <span className="click-indicator">
                                                <FaAngleDown className="blink-arrow" />
                                                <span className="click-text">Click to view</span>
                                            </span>
                                        }
                                      </h4>
                                      <div className="question-actions">
                                        {question.google_doc_link && (
                                          <a 
                                            href={question.google_doc_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="view-problem-btn"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <FaExternalLinkAlt /> Original Problem Statement
                                          </a>
                                        )}
                                        <div className="solution-button-container">
                                          {expandedSolutionButton === question.id ? (
                                            <form 
                                              className="solution-email-form"
                                              onSubmit={(e) => handleSolutionSubmit(question.id, company.name, question.title, e)}
                                              onClick={(e) => e.stopPropagation()}
                                            >
                                              <div className="solution-form-content">
                                                <input
                                                  type="email"
                                                  placeholder="Enter your email"
                                                  value={solutionEmail}
                                                  onChange={(e) => setSolutionEmail(e.target.value)}
                                                  required
                                                  disabled={isSubmitting}
                                                />
                                                <button type="submit" disabled={isSubmitting}>
                                                  {isSubmitting ? (
                                                    <div className="simple-spinner">
                                                      <div className="spinner-dot"></div>
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <FaEnvelope /> Send
                                                    </>
                                                  )}
                                                </button>
                                              </div>
                                            </form>
                                          ) : (
                                            <button 
                                              className="get-solution-btn"
                                              onClick={(e) => handleSolutionClick(question.id, e)}
                                            >
                                              <FaLock /> Get Solution
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {expandedProblem === question.id && (
                                    <div 
                                      className="problem-statement"
                                      dangerouslySetInnerHTML={{ 
                                        __html: DOMPurify.sanitize(formatProblemStatement(question.problem_statement))
                                      }} 
                                    />
                                  )}
                                </div>
                              ))
                            ) : (
                              <p>No questions available for this company.</p>
                            )}
                          </div>
                        )}
                      </div>
                    )) : filterCompanies().map((company) => (
                      <div key={company.id} className="company-row">
                        <div className="company-data" onClick={() => toggleCompany(company.id)}>
                          <div className="company-name">
                            <div className="company-icon-wrapper">
                              {React.createElement(getCompanyIcon(company.name), {
                                className: "company-icon"
                              })}
                            </div>
                            <span className="company-name-text">{company.name}</span>
                          </div>
                          <div className="date-container">
                            {new Date(company.date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })}
                            <span className="date-ago">
                              {getTimeAgo(new Date(company.date))}
                            </span>
                          </div>
                          <div className="questions-count">
                            {company.questions ? company.questions.length : 0}
                          </div>
                          <div className="company-solutions">
                            <span className={`solution-badge ${company.solutions_available ? 'available' : 'unavailable'}`}>
                              {company.solutions_available ? 'Available' : 'Unavailable'}
                            </span>
                          </div>
                          <div className="toggle-icon desktop-only">
                            {expandedCompany === company.id ? <FaChevronUp /> : <FaChevronDown />}
                          </div>
                        </div>
                        {expandedCompany === company.id && (
                          <div className="company-questions">
                            {company.questions.length > 0 ? (
                              company.questions.map((question, index) => (
                                <div key={question.id} className="question-item">
                                  <div className="question-preview">
                                    <div className="question-info">
                                      <span className="question-number">{index + 1}.</span>
                                      <h4 
                                        className="question-title"
                                        onClick={(e) => handleProblemClick(question)}
                                      >
                                        {question.title}
                                        {!expandedProblem && 
                                            <span className="click-indicator">
                                                <FaAngleDown className="blink-arrow" />
                                                <span className="click-text">Click to view</span>
                                            </span>
                                        }
                                      </h4>
                                      <div className="question-actions">
                                        {question.google_doc_link && (
                                          <a 
                                            href={question.google_doc_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="view-problem-btn"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <FaExternalLinkAlt /> Original Problem Statement
                                          </a>
                                        )}
                                        <div className="solution-button-container">
                                          {expandedSolutionButton === question.id ? (
                                            <form 
                                              className="solution-email-form"
                                              onSubmit={(e) => handleSolutionSubmit(question.id, company.name, question.title, e)}
                                              onClick={(e) => e.stopPropagation()}
                                            >
                                              <div className="solution-form-content">
                                                <input
                                                  type="email"
                                                  placeholder="Enter your email"
                                                  value={solutionEmail}
                                                  onChange={(e) => setSolutionEmail(e.target.value)}
                                                  required
                                                  disabled={isSubmitting}
                                                />
                                                <button type="submit" disabled={isSubmitting}>
                                                  {isSubmitting ? (
                                                    <div className="simple-spinner">
                                                      <div className="spinner-dot"></div>
                                                    </div>
                                                  ) : (
                                                    <>
                                                      <FaEnvelope /> Send
                                                    </>
                                                  )}
                                                </button>
                                              </div>
                                            </form>
                                          ) : (
                                            <button 
                                              className="get-solution-btn"
                                              onClick={(e) => handleSolutionClick(question.id, e)}
                                            >
                                              <FaLock /> Get Solution
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {expandedProblem === question.id && (
                                    <div 
                                      className="problem-statement"
                                      dangerouslySetInnerHTML={{ 
                                        __html: DOMPurify.sanitize(formatProblemStatement(question.problem_statement))
                                      }} 
                                    />
                                  )}
                                </div>
                              ))
                            ) : (
                              <p>No questions available for this company.</p>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <PaginationControls />
        </main>
      )}
    </div>
  );
};

export default CompanyOA;
